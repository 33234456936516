<template>
  <div v-if="reviews.length" class="partners-reviews">
    <div class="container">
      <h2 class="title">{{ $t("reviewsTitle") }}</h2>
      <div class="partners-reviews__items">
        <div
          v-for="(review, index) in reviews"
          :key="index"
          class="partners-reviews__item review-item"
        >
          <div class="review-item__info">
            <div class="review-item__header">
              <div class="review-item__avatar">
                <img src="@/assets/img/icons/user.svg" alt="user photo" />
              </div>
              <div class="review-item__user">
                <div class="review-item__user-name">
                  {{ review.attributes.name }}
                </div>
                <div class="review-item__user-position">
                  {{ review.attributes.position }}
                </div>
              </div>
            </div>
            <div class="review-item__descr">
              <div class="review-item__text">
                {{ review.attributes.review }}
              </div>
            </div>
          </div>
          <time class="review-item__time">{{
            review.attributes.review_date
          }}</time>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getReviews } from "@/api/reviews";
export default {
  name: "PartnersReviews",
  data() {
    return {
      reviews: [],
    };
  },
  created() {
    getReviews().then((res) => {
      this.reviews = res;
    });
  },
};
</script>
